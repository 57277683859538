import { store } from "@/store/store";
import { grupo_usuario } from "@/shared/dtos/grupo_usuarios/grupo_usuario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { grupo_usuarioAllDto } from "@/shared/dtos/grupo_usuarios/grupo_usuarioAllDto";

@Module({
  namespaced: true,
  name: "grupo_usuarioModule",
  store,
  dynamic: true,
})
class grupo_usuarioModule extends VuexModule {
  public grupo_usuarios: grupo_usuario[] = [];
  public grupo_usuario: grupo_usuario = new grupo_usuario();
  public grupo_usuario_ficha: grupo_usuarioAllDto = new grupo_usuarioAllDto();

  @Action({ commit: "onGetgrupo_usuarios" })
  public async getgrupo_usuarios() {
    return await ssmHttpService.get(API.grupo_usuario);
  }

  @Action({ commit: "onGetgrupo_usuario" })
  public async getgrupo_usuario(id: any) {
    return await ssmHttpService.get(API.grupo_usuario + "/" + id);
  }

  @Action({ commit: "onGetgrupo_usuario_ficha" })
  public async getgrupo_usuario_ficha(id: any) {
    return await ssmHttpService.get(API.grupo_usuario + "/ficha/" + id);
  }

  @Action
  public async guardargrupo_usuario(grupo_usuario: grupo_usuario) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.grupo_usuario, grupo_usuario.toJson());
  }
  @Action
  public async guardargrupo_usuarioficha(grupo_usuario: grupo_usuarioAllDto) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.grupo_usuario + "/ficha/",
      grupo_usuario.toJson()
    );
  }

  @Action
  public async modificargrupo_usuario(grupo_usuario: grupo_usuario) {
    return await ssmHttpService.put(
      API.grupo_usuario + "/" + grupo_usuario.id,
      grupo_usuario
    );
  }

  @Action
  public async modificargrupo_usuarioficha(grupo_usuario: grupo_usuarioAllDto) {
    return await ssmHttpService.put(
      API.grupo_usuario + "/ficha/",
      grupo_usuario
    );
  }

  @Action
  public async eliminargrupo_usuarioficha(grupo_usuario: grupo_usuarioAllDto) {
    return await ssmHttpService.delete(
      API.grupo_usuario + "/ficha/" + grupo_usuario.id,
      null,
      false
    );
  }

  @Action
  public async eliminargrupo_usuario(grupo_usuario: grupo_usuario) {
    return await ssmHttpService.delete(
      API.grupo_usuario + "/" + grupo_usuario.id,
      null,
      false
    );
  }

  @Mutation
  public onGetgrupo_usuarios(res: grupo_usuario[]) {
    this.grupo_usuarios = res ? res.map((x) => new grupo_usuario(x)) : [];
  }

  @Mutation
  public onGetgrupo_usuario(res: grupo_usuario) {
    this.grupo_usuario = new grupo_usuario(res);
  }

  @Mutation
  public onGetgrupo_usuario_ficha(res: grupo_usuarioAllDto) {
    this.grupo_usuario_ficha = res;
  }
}
export default getModule(grupo_usuarioModule);
